<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="190px" :inline="true">
                <el-form-item label="电池电压恢复至市电电压：" prop="batteryVoltageBackToUtility">
                    <el-select v-model="dataForm.batteryVoltageBackToUtility">
                        <el-option v-for="item in backToBatteryOptions" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import global from '@/components/Global';
import message from '@/utils/message';
import mqttService from '@/api/mqtt-service';
import {queryEsaioManagerJsonSchemaDetail} from '@/api/jsonschema/jsonSchemaApi';
import jsonSchema from '@/api/jsonschema/json-schema';

export default {

    name: "battery-voltage-back-to-utility",
    data() {

        return {

            deviceSn: '',
            dataForm: {

                batteryVoltageBackToUtility: ''
            },
            command: {

                commandCodeR: 'get_rate_info',
                commandCodeW: 'set_battery_vol_back_to_utility',
            },
            backToBatteryOptions: [
                {
                    name : '44V',
                    value: '44.0'
                },
                {
                    name : '45V',
                    value: '45.0'
                },
                {
                    name : '46V',
                    value: '46.0'
                },
                {
                    name : '47V',
                    value: '47.0'
                },
                {
                    name : '48V',
                    value: '48.0'
                },
                {
                    name : '49V',
                    value: '49.0'
                },
                {
                    name : '50V',
                    value: '50.0'
                },
                {
                    name : '51V',
                    value: '51.0'
                }
            ],
            jsonSchemaData: {}
        }
    },
    methods: {
        /**
         * 查询初始化.
         */
        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('电池电压恢复至市电电压读取中...', 30);
            let that = this;
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        that.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
            // 查询jsonSchema
            this.queryWriteCommandJsonSchema();
        },
        /**
         * 设置提交.
         */
        submit() {

            if (this.jsonSchemaData) {

                let check = jsonSchema.validate(this.jsonSchemaData, this.dataForm);
                if (!check.pass) {

                    message.error('参数错误');
                    return;
                }
            }
            let that = this;
            this.cmdLoading = loading.loading('设置电池电压恢复至市电电压指令下发中...', 30);
            let option = {

                loading: that.cmdLoading,
                data: {

                    deviceSn: that.deviceSn,
                    commandCode: that.command.commandCodeW,
                    data: that.dataForm
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('设置电池电压恢复至市电电压成功');
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        },
        /**
         * 查询写指令的的json schema校验信息
         */
        queryWriteCommandJsonSchema() {

            let param = {

                deviceSn: this.deviceSn,
                commandCode: this.command.commandCodeW
            };
            queryEsaioManagerJsonSchemaDetail(param).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.jsonSchemaData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        /**
         * 查询指令回调.
         */
        initSuccessCallBack(data) {

            this.dataForm.batteryVoltageBackToUtility = data.batteryRechargeVoltage;
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%
}

.btn-box {

    text-align: center;
}
</style>